import React, { useEffect, useState } from "react";
import AuthLayout from "../../../layouts/AuthLayout";
import TextHeader from "../../../components/frontv2.0/common/text/TextHeader";
import InputCustom from "../../../components/frontv2.0/common/form/InputCustom";
import ButtonCustom from "../../../components/frontv2.0/common/button/ButtonCustom";
import Logo from "../../../components/frontv2.0/common/logo/Logo";
import { FAVICON_WITHE, IMAGE_1 } from "../../../constants/images";
import Alert from "../../../components/frontv2.0/common/alert/Alert";
import Illustration from "../../../components/frontv2.0/common/illustration/Illustration";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../../../network/services/UserService";
import {
    APP_ROLES,
    getAuthSettings,
    getLastUrl,
    hasRole,
    isAuthenticated,
    removeLastUrl,
    saveAuthSettings,
    setLastUrl,
    signIn,
    getUser,
} from "../../../network/auth/Auth";
import { getDomain } from "../../../helpers/domainToolKit";
import { EMAIL_REGEX } from "../../../constants/Constants";
import Loader from "../../../components/frontv2.0/common/loader/Loader";
import { MEDIA_URL_ACCOUNTING_AUTH_SETTINGS } from "../../../network/urls/backendUrls";

const SignIn = () => {
    // States
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [requestAttempt, setRequestAttempt] = useState(false);
    const [requestSuccess, setRequestSuccess] = useState(true);
    const [fileUrl, setfileUrl] = useState("");
    const [action, setAction] = useState("");
    const [loginMessage, setLoginMessage] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isActivatingAccount, setIsActivatingAccount] = useState(false);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [firstLoading, setFirstLoading] = useState(true)
    const [showPassword, setShowPassword] = useState(false);
    const wordpressUrl = process.env.REACT_APP_WORDPRESS;

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const navigates = useNavigate();

    const handleRedirect = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        let microserviceUrl = queryParams.get("redirect");

        setFirstLoading(true);
        if (microserviceUrl) {
            setLastUrl(microserviceUrl);

            if (isAuthenticated()) {
                let domainRegularExpression =
                    /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^/?\n]+)/igm;
                let domainMatch = domainRegularExpression.exec(microserviceUrl);

                if (domainMatch) {
                    let domain = domainMatch[1];
                    if (
                        domain === process.env.REACT_APP_WORDPRESS_DOMAIN ||
                        domain === getDomain(process.env.REACT_APP_MOODLE)
                    ) {
                        const { otp } = await UserService.generateOtp();
                        if (otp) {
                            removeLastUrl();
                            window.location = microserviceUrl + "?otp=" + otp;
                            return;
                        }
                    }
                }
            }
        } else if (isAuthenticated()) {
            //  navigates("/workspace/mysessions")
            let user = getUser()
            if (user.role === "ROLE_USER") {
                window.location.assign(wordpressUrl);
            } else {
                navigates("/workspace/mysessions");
            }
            return;

        }
        setFirstLoading(false);
    };

    const handleSignIn = async (e) => {
        e.preventDefault();
        // const { username, password } = state;
        let tryLogin = username.length > 0 && password.length > 0;
        let homeSpace = "/workspace";

        if (isValidEmail && tryLogin) {
            setLoading(true);

            if (process.env.REACT_APP_COURSE_HOME === "WP") {
                const wpAccount = await UserService.verifyAccountOnWordpress({
                    username,
                    password,
                });
                if (!wpAccount.success) {
                    setRequestAttempt(true);
                    setRequestSuccess(wpAccount.success);
                    setMessage(wpAccount.message);
                    setLoading(false);
                    return;
                }
            }

            UserService.login({ username, password }, async (data) => {
                if (data != null) {
                    if (
                        data.secretKey !== null &&
                        (hasRole({ role: data.role }, APP_ROLES.ROLE_MANAGER) ||
                            data.role === APP_ROLES.ROLE_SUPPORT)
                    ) {
                        navigates({
                            pathname: "/confirm-identity",
                            search:
                                "?email=" +
                                encodeURIComponent(username) +
                                "&srt=" +
                                encodeURIComponent(data.secretKey),
                        });
                        return;
                    }

                    setRequestSuccess(data.success);
                    setMessage(data.message);
                    setRequestAttempt(true);
                    setLoading(false);


                    if (data.success) {
                        console.log(data)
                        signIn(data);
                        // const lastUrl = getLastUrl();                                        
                        const lastUrl = wordpressUrl;
                        if (
                            data.role === APP_ROLES.ROLE_ACCOUNTING ||
                            data.role === APP_ROLES.ROLE_ADMIN
                        ) {
                            homeSpace = "/admin";
                        }
                        // else if (data.role === APP_ROLES.ROLE_USER) {
                        //     homeSpace = "/workspace/mysessions";
                        // }

                        else if (data.role === APP_ROLES.ROLE_USER) {
                            window.location.assign(lastUrl);
                        }

                        if (lastUrl) {
                            let domainRegularExpression =
                                /^(?:https?:\/\/)?(?:[^@\\/\n]+@)?(?:www\.)?([^\\/?\n]+)/igm;
                            let domainMatch = domainRegularExpression.exec(lastUrl);
                            if (domainMatch) {
                                let domain = domainMatch[1];
                                if (
                                    domain === process.env.REACT_APP_WORDPRESS_DOMAIN ||
                                    domain === getDomain(process.env.REACT_APP_MOODLE)
                                ) {
                                    const { otp } = await UserService.generateOtp();
                                    if (otp) {
                                        removeLastUrl();
                                        window.location = lastUrl + "?otp=" + otp;
                                        return;
                                    }
                                }
                            }
                        }
                        window.location = lastUrl ? lastUrl : homeSpace;

                    } else if (data.action === 1) {
                        setAction(1);
                    }
                }
            });
        }
    };

    const filterSettings = (response) => {
        const elt = response.filter((e) => e.page === "AUTH_LOGIN")[0]
        if (elt) {
            setLoginMessage(elt.message)
            setfileUrl(elt.file);
        }
    }
    const getLoginAuthSettings = () => {
        setFirstLoading(true);
        const settings = getAuthSettings();

        if (settings && settings.length > 0) {
            filterSettings(settings);
            //setLoginMessage(settings.message);
            //setfileUrl(settings.file);
        } else {

            UserService.getAuthSetting((response) => {
                if (response) {
                    if (response.success) {
                        saveAuthSettings(response.data);
                        filterSettings(response.data);
                    }
                }
            });
        }
        setFirstLoading(false);
    };


    const resendActivationEmail = (e) => {
        e.preventDefault();
        UserService.resendActivationEmail({ email: username }, (data) => {
            if (data != null) {
                setAction(0);
                setRequestSuccess(data.success);
                setMessage(data.message);
            }
        });
    };

    useEffect(() => {
        getLoginAuthSettings();
        handleRedirect();
        const queryParams = new URLSearchParams(window.location.search);
        let token = queryParams.get("token");
        let tokenRes = queryParams.get("tokenres");
        let email = queryParams.get("email");
        let success = queryParams.get("success");
        let message = queryParams.get("message");

        if (token && email) {
            setLoading(true);
            setIsActivatingAccount(true);
            UserService.activateAccount({ email, token }, (data) => {
                if (data != null) {
                    setRequestSuccess(data.success);
                    setMessage(data.message);
                    setLoading(false);
                }
                setRequestAttempt(true);
                setIsActivatingAccount(false);
                setLoading(false);
            });
        } else if (tokenRes && email) {
            setLoading(true);
            UserService.checkPasswordResetToken(
                { email, token: tokenRes },
                (data) => {
                    if (data != null) {
                        setRequestSuccess(data.success);
                        setMessage(data.message);
                        if (data.success) {
                            const navigate = navigates;
                            navigate({
                                pathname: "/resetpassword",
                                search:
                                    "?email=" +
                                    encodeURIComponent(email) +
                                    "&tokenres=" +
                                    encodeURIComponent(tokenRes),
                            });
                        }
                    }
                    setLoading(false);
                    setRequestAttempt(true);
                }
            );
        } else if (message && success) {
            setRequestAttempt(true);
            setRequestSuccess(success);
            setMessage(message);
            setLoading(false);
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return firstLoading ? (
        <>
            <div className="tw-h-screen tw-w-full tw-flex tw-justify-center tw-items-center">
                <Loader />
            </div>
        </>
    ) : (
        <AuthLayout>
            <AuthLayout.Body>
                <div className="tw-flex tw-flex-col tw-justify-center tw-px-6 tw-py-10 lg:tw-px-8 tw-h-full">
                    <div
                        className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm tw-items-center tw-flex tw-justify-center sm:tw-justify-start">
                        <Logo width={200} />
                    </div>
                    <div
                        className="tw-w-full sm:tw-mx-auto sm:tw-max-w-sm tw-h-full tw-flex sm:tw-items-center sm:tw-my-auto ">
                        <div className="tw-w-full">
                            <div
                                className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm sm:tw-justify-start tw-flex tw-justify-center tw-items-center tw-mt-8 md:hidden sm:tw-mt-0 ">
                                <div
                                    className="tw-w-full tw-justify-center tw-items-center tw-flex tw-flex-col sm:tw-block">
                                    <TextHeader label={"Login"} textUnderline={"Now"} size={3} />
                                    <p className="tw-mt-2 tw-mb-8 tw-text-gray-500 tw-text-[16px] tw-font-normal tw-text-center sm:tw-text-start">
                                        Sign in below to access your courses.
                                    </p>
                                </div>
                            </div>
                            <div className="tw-w-full">
                                {requestAttempt ? (
                                    <>
                                        <Alert
                                            type={requestSuccess ? "success" : "danger"}
                                            message={message}
                                        />
                                        {action === 1 ? (
                                            <ButtonCustom
                                                classes={
                                                    "tw-text-white tw-bg-[#309255] hover:tw-bg-[#055021]"
                                                }
                                                label={"Click here to resend the activation email"}
                                                onClick={resendActivationEmail}
                                            />
                                        ) : null}
                                    </>
                                ) : null}

                                <form className="tw-w-full">
                                    <div className="">
                                        <InputCustom
                                            id={"email"}
                                            name={"email"}
                                            type={"email"}
                                            icon={"pi pi-envelope"}
                                            placeHolder={"E-mail"}
                                            value={username}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setUsername(value);
                                                if (value.length > 0) {
                                                    setIsValidEmail(true);
                                                }
                                            }}
                                            onBlur={(e) => {
                                                const email = e.target.value;
                                                setUsername(email);
                                                if (email.match(EMAIL_REGEX)) {
                                                    setIsValidEmail(true);
                                                } else if (email === "") {
                                                    setIsValidEmail(true);
                                                } else {
                                                    setIsValidEmail(false);
                                                }
                                            }}
                                            error={
                                                isValidEmail ? "" : `The e-mail address is invalid.`
                                            }
                                        />
                                    </div>

                                    <div className="tw-mt-6">
                                        <InputCustom
                                            id={"password"}
                                            name={"password"}
                                            type={"password"}
                                            icon={"pi pi-lock"}
                                            placeHolder={"Password"}
                                            onChange={(e) => {
                                                const password = e.target.value;
                                                setPassword(password);

                                            }}
                                            showPassword={showPassword}
                                            handleShowPassword={handleShowPassword}
                                        />
                                    </div>
                                    <div className="tw-flex tw-justify-end tw-mt-1 tw-text-sm">
                                        <Link to="/lostpassword">
                                            <span className="tw-underline tw-text-primary-800 hover:tw-text-primary-700">
                                                Forgot your password?
                                            </span>
                                        </Link>
                                    </div>

                                    <div className="tw-mt-8">
                                        {loading || isActivatingAccount ? (
                                            <ButtonCustom
                                                classes={
                                                    "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                                                }
                                                label="Loading..."
                                                icon={"pi pi-spin pi-spinner"}
                                                disabled={true}
                                            />
                                        ) : (
                                            <ButtonCustom
                                                classes={
                                                    "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                                                }
                                                label="Login"
                                                disabled={!(username.length > 0 && password.length > 0)}
                                                onClick={handleSignIn}
                                            />
                                        )}
                                    </div>

                                    <p className="tw-mt-4 tw-text-sm tw-text-center tw-text-gray-500">
                                        Don't have an account? Sign up below.
                                    </p>
                                    <div className="tw-mt-3">
                                        <Link to="/register">
                                            <ButtonCustom
                                                classes={
                                                    "tw-border-[1.5px] tw-border-[#309255] tw-text-[#309255] hover:tw-text-white hover:tw-bg-[#309255]"
                                                }
                                                label="Sign up here"
                                            />
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </AuthLayout.Body>

            <AuthLayout.Illustration>
                <Illustration
                    favIcon={FAVICON_WITHE}
                    image={fileUrl ?
                        MEDIA_URL_ACCOUNTING_AUTH_SETTINGS + fileUrl
                        :
                        IMAGE_1}
                    label={loginMessage ? loginMessage : `"Linux, AWS and DevOps Training."`}
                />
            </AuthLayout.Illustration>
        </AuthLayout>
    );
};

export default SignIn;
